// @ts-strict-ignore
import { SyntheticEvent, useState } from 'react';
import styled from 'styled-components';
import FacebookLoginButton from 'src/modules/shared/auth/components/facebookLoginButton';
import GoogleLoginButton from 'src/modules/shared/auth/components/googleLoginButton';
import { Category, isCategoryAccepted, useCookieCategory } from 'src/utils/cookie';
import i18n from 'src/utils/translate';
import { translationWithLink } from 'src/utils/i18n/translationUtils';
import { LinkButton } from 'styles-js/buttons';
import { textLinkPrimarySmall, textSmall } from 'styles-js/mixins/typography';
type Props = {
  isCommunitySignup?: boolean;
  redirectPage?: () => void;
  onLoginSuccess?: () => void;
};
export default function SocialLoginButtons({
  isCommunitySignup,
  redirectPage,
  onLoginSuccess
}: Props) {
  const [isFunctionalityAccepted, setIsFunctionalityAccepted] = useCookieCategory(Category.Functionality);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const onEnableCookiesClick = () => {
    // Change consent to the functionality cookies
    // Show success message and force re-render of GoogleLoginButton and FacebookLoginButton
    setIsFunctionalityAccepted(true);
    setShowSuccessMessage(true);
  };
  const onMouseUp = () => {
    // When the cookie management modal is closed, check functionality cookies consent
    // Cookies rejected, display the 'Not working? Enable functionality cookies' link
    setIsFunctionalityAccepted(isCategoryAccepted(Category.Functionality));
    setShowSuccessMessage(isCategoryAccepted(Category.Functionality));
    const closeButtonNode = document.getElementById('ccc-close');
    if (closeButtonNode) closeButtonNode.removeEventListener('mouseup', onMouseUp);
  };
  const onChangePreferencesClick = (e: SyntheticEvent) => {
    e.preventDefault();
    window?.CookieControl?.open();
    // If the user disable cookies from the modal, the only way to update the state of the sign up box
    // is to check the cookies consent when they close the cookie management modal.
    // Wait to be sure the cookie management modal is added to the DOM
    setTimeout(() => {
      // Add event listener on close button of cookie management modal
      const closeButtonNode = document.getElementById('ccc-close');
      if (closeButtonNode) closeButtonNode.addEventListener('mouseup', onMouseUp, false);
    }, 500);
  };
  return <>
      <div data-testid="social-login-buttons">
        <GoogleLoginButton isCommunitySignup={isCommunitySignup} isFunctionalityCookiesEnabled={isFunctionalityAccepted} redirectPage={redirectPage} onLoginSuccess={onLoginSuccess} data-sentry-element="GoogleLoginButton" data-sentry-source-file="socialLoginButtons.tsx" />
        <FacebookLoginButton isCommunitySignup={isCommunitySignup} isFunctionalityCookiesEnabled={isFunctionalityAccepted} redirectPage={redirectPage} onLoginSuccess={onLoginSuccess} data-sentry-element="FacebookLoginButton" data-sentry-source-file="socialLoginButtons.tsx" />
      </div>
      {!isFunctionalityAccepted && <SmallLinkButton onClick={onEnableCookiesClick}>
          {i18n.t('Not working? Enable functionality cookies')}
        </SmallLinkButton>}
      {showSuccessMessage && <SmallP>
          {translationWithLink(i18n.t('Your preferences have been updated to enable functionality cookies. You can %1$schange your preferences%2$s at any time'), {
        href: '#',
        onClick: onChangePreferencesClick
      })}
        </SmallP>}
    </>;
}
const SmallLinkButton = styled(LinkButton)`
  ${textLinkPrimarySmall};
  white-space: normal;
`;
const SmallP = styled.p`
  ${textSmall};
`;