import { SyntheticEvent } from 'react';
type LinkProps = {
  href: string;
  target?: string;
  onClick?: (e: SyntheticEvent) => void;
};
export const translationWithLink = (text: string, linkProps: LinkProps = {
  href: '#'
}) => {
  const selector = /(.*)%\d\$s(.*)%\d\$s(.*)/;
  const matches = text.match(selector);
  if (matches === null || matches && matches.length < 4) {
    return null;
  }
  return <span data-sentry-component="translationWithLink" data-sentry-source-file="translationUtils.tsx">
      {matches[1]}
      <a href={linkProps.href} target={linkProps.target} onClick={linkProps.onClick}>
        {matches[2].trim()}
      </a>
      {matches[3]}
    </span>;
};