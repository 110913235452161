import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px; // max size for the Google button
    margin-right: 0;
    font-size: 15px;
    color: ${props => props.theme.colorTextBlack};
    border-color: ${({ theme }) => theme.colorGreyLight};
    &:hover, &:focus, &:active {
      color: ${props => props.theme.colorTextBlack};
      border-color: ${({ theme }) => theme.colorGreyDark};
      background-color: ${({ theme }) => theme.colorWhite};
    }
  }
  span {
    margin-left: 10px;
  }
`;
